import { useState, useContext, useEffect, useRef, useCallback} from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import AssetCard from './AssetCard'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import cn_meta from '../cn_db.json'
import gc_meta from '../gc_db.json'
import pitches_meta from '../pitches_db.json'

import pants_meta from '../pants_db.json'

import {MainClaimContext} from './ClaimContext'

function MarketListings(props) {

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)


  const clay_token_id = '38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535434c4159'

  const collectionPolicies = {
                            "cn":"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
                            "gc":"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
                            "pitches":"13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e",
                            "pants":"90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771"
                         }

  const collections_meta = {
                            "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":cn_meta,
                            "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":gc_meta,
                            "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":pitches_meta,
                            "90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771":pants_meta
                        }

   const cardThemes = {
    "cn":{title: "Clay Nation", image: 'CN_CARD.svg', boxShadow: "#FF9C83", color:"bisque"},
    "gc":{title: "CN x Good Charlotte", image: 'GC_CARD.svg', boxShadow: "#53C9EE", color: "bisque"},
    "pitches":{title: "Pitches at Clay Nation", image: 'PITCHES_CARD.svg', boxShadow: "#657389", color: "bisque"},
    "other":{title: "Clay Nation", image: 'CN_CARD.svg', boxShadow: "#FF9C83", color:"bisque"}
  }


  const collection = props.collection

  const sellAsset = props.sellAsset 
  const setSellAsset = props.setSellAsset

  const listingsFilters = props.listingFilters
  const listingsPage = props.listingsPage
  const setListingsPage = props.setListingsPage

  const [currentSlot, setCurrentSlot] = useState(0)

  const [clayPrice, setClayPrice] = useState(0) 

  const [listings, setListings] = useState([])

  const [tempListings, setTempListings] = useState([])

  const [testList, setTestList] = useState([1,2,3,4,5,6,7,8,9,10])

  //const [existingKeys, setExistingKeys] = useState([])

  const appliedFilters = props.appliedFilters


  const sortOrder = props.sortOrder


  const matchesL = props.matchesL
  const matchesM = props.matchesM
  const matchesS = props.matchesS
  const matchesXS = props.matchesXS

  const isSideBarOpen = props.isSideBarOpen



  const yourAssetsFilters = props.yourAssetsFilters


  const yourAssets = yourAssetsFilters.yourAssets
  const setYourAssets = yourAssetsFilters.setYourAssets

  const listedOnly = yourAssetsFilters.listedOnly
  const setListedOnly = yourAssetsFilters.setListedOnly

  const notListedOnly = yourAssetsFilters.notListedOnly
  const setNotListedOnly = yourAssetsFilters.setNotListedOnly


  const searchQuery = props.searchQuery
  const setSearchQuery = props.setSearchQuery




    async function getCurrentSlot(){
        let slot = await fetch('https://claymarket.io/api/current/slot/').then(response => response.json())
                                                                      .then(data => data)
        slot = parseInt(slot.slot, 10)

        return slot
    }






  useEffect(()=>{
    
    get_clay_price()

  },[])



  useEffect(()=>{

    if (collection){

      setListings([])
      performFetching([],1)

    }


  },[appliedFilters, sortOrder, yourAssetsFilters.yourAssets, yourAssetsFilters.listedOnly, yourAssetsFilters.notListedOnly, searchQuery])



  useEffect(()=>{
    if (collection){

        if (yourAssetsFilters.yourAssets && !searchQuery){
            setListings([])
            performFetching([],1)
      }

    }
  }, [stake_address])




  useEffect(()=>{

    if (listingsPage > 1){
        performFetching(listings, listingsPage)
    }

  },[listingsPage])



async function performFetching(existingListings, page){
    if (isLoading == false){
        setIsLoading(true)
        setListingsPage(page)
        await get_market_listings(existingListings, page)
        setIsLoading(false) 
    }
}


async function performFetching_2(existingListings, page){
    
    setIsLoading(true)
    setListingsPage(page)
    await get_market_listings(existingListings, page)
    setIsLoading(false) 
    
}



async function get_market_listings(existingListings, page){

    let temp_currentSlot = currentSlot

    if (temp_currentSlot == 0){

        //console.log('NEED FETCHING SLOT')

        temp_currentSlot = await getCurrentSlot()

        setCurrentSlot(temp_currentSlot)

    }

    //console.log(appliedFilters)

    let query_url = 'https://claymarket.io/api/filter/listings/?collection=' + collection + '&page=' + String(page)

    //if (!searchQuery){

        for (let key in appliedFilters){

            if (Array.isArray(appliedFilters[key])){
                for (let i = 0;  i < appliedFilters[key].length; i++){
                    query_url += '&' + key + '=' +  appliedFilters[key][i].replaceAll(" ","_")
                 }
            }else{
                 query_url += '&' + key + '=' +  appliedFilters[key]
            }
        }


        if (sortOrder){
            query_url += '&order=' + sortOrder
        }

        if (yourAssets && stake_address){
            query_url += '&stake_address=' + stake_address
        }

        if (clayPrice){
            query_url += '&clay_price=' + clayPrice
        }

     
    query_url += searchQuery
        
    
    let asset_listing_info = await fetch(query_url).then(response => response.json())
                                   .then(data => data)


    
    if ('listings' in asset_listing_info){
        asset_listing_info = asset_listing_info['listings']


        if (asset_listing_info.length > 0){
            setHasMore(true)
            //console.log(asset_listing_info.length)
        }else{
            setHasMore(false)
        }
        
    }else{
        setHasMore(false)
        return
    }
    

    let temp_listings = []

    for (let i = 0; i < asset_listing_info.length; i++){

        let cur_asset_listing = asset_listing_info[i]
        let asset_id = cur_asset_listing['asset_id']

        let assetPrice = 0 

        let ada_price = cur_asset_listing['ada_price']
        let clay_price = cur_asset_listing['clay_price']

        let upgraded = false

        try{
            upgraded = cur_asset_listing['upgraded']
        }catch{
            //ignore
        }
        


        let accumulatedToken
        if (parseInt(temp_currentSlot, 10) > 80000000){

             if (asset_id.startsWith('40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728')){
                  if (temp_currentSlot > 134345140){
                    temp_currentSlot = 134345140
                  }
                }else if (asset_id.startsWith('94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768')){
                  if (temp_currentSlot > 138920740){
                    temp_currentSlot = 138920740
                  }
                }


             accumulatedToken = (temp_currentSlot - cur_asset_listing['bake_slot'] - 1209600) * parseFloat(cur_asset_listing['bake_rate'],10) / 86400 
        


             if (asset_id.startsWith('40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728')){
                  if (temp_currentSlot == 134345140 && accumulatedToken < 0){
                    accumulatedToken = 1
                  }
                }else if (asset_id.startsWith('94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768')){
                  if (temp_currentSlot == 138920740 && accumulatedToken < 0){
                    accumulatedToken = 1
                  }
                }

        }else{
            accumulatedToken = 0
        }
       
        accumulatedToken = Math.round(accumulatedToken)


        let listing_owner = ''

        if (cur_asset_listing['owner_stake_address']){
            listing_owner = cur_asset_listing['owner_stake_address']
        }else{
            listing_owner = cur_asset_listing['stake_address']
        }
       
        let tx_hash = cur_asset_listing['tx_id'] + '#' + String(cur_asset_listing['tx_index'])
        let asset_info = {meta:cur_asset_listing['meta'], 
                          assetPrice:{"ada_price":ada_price, "clay_price":clay_price},
                          accumulatedToken: accumulatedToken,
                          upgraded: upgraded,
                          address:cur_asset_listing['address'],
                          owner_stake_address: listing_owner
                          }

        let assetStatus = ''
        if (ada_price > 0 || clay_price > 0){

            if (cur_asset_listing['owner_stake_address'] == stake_address){
                assetStatus = 'updatable'
            }else{
                assetStatus = 'listed'
            }
         if (!yourAssets || (yourAssets && listedOnly) ){
            temp_listings.push({asset_id:asset_id, tx_hash:tx_hash, asset_info:asset_info, assetStatus:assetStatus})
         }
         
        }else{
            
            if (cur_asset_listing['stake_address'] == stake_address){
                assetStatus = 'sellable'
            }else{
                assetStatus = 'not listed'
            }
            

            if (!yourAssets || (yourAssets && notListedOnly) ){
                temp_listings.push({asset_id:asset_id, tx_hash:tx_hash, asset_info:asset_info, assetStatus:assetStatus})
            }
        }
    }

    
    //console.log(temp_listings)

    if (temp_listings.length == 0){
        setHasMore(false)
    }

    setListings([...existingListings, ...temp_listings])

    //console.log('NUMBER OF LISTINGS')
    //console.log(listings.length)

  }

  async function get_clay_price(){

     let fetched_current_stats = await fetch('https://clayapi.com/claystats/current/').then(response => response.json())
                                                                  .then(data => data)

     let temp_clay_price = fetched_current_stats['clay_price'].toFixed(6)
     //console.log(temp_clay_price)
     setClayPrice(temp_clay_price)

  } 


const [isLoading, setIsLoading] = useState(false)
const [hasMore, setHasMore] = useState(true)
const [pageNum, setPageNum] = useState(1)
const observer = useRef();

const lastCardElementRef = (node) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setListingsPage((prev) => prev + 1);
        }
      });

    if (node) observer.current.observe(node);
}


   
return (


    <div id="listings" style={{marginTop:'0px', width:'98%', backgroundColor:'', minHeight:'300px', marginBottom:'100px'}}>

            <Container>

            <Grid container spacing={6} style={{backgroundColor:'', justifyContent:'center', display:'flex'}}>
            
            {listings && listings.map((listing_item, i) =>    
                   

                   listings.length === i + 1 ? 

                   (

                    <Grid item xs={12} sm={isSideBarOpen ? 10 : 6} md={6} lg={4} xl={3} key={listing_item['asset_id'] + listing_item['tx_hash']}


                    style={!matchesS ? {transform:'scale(1)',  marginLeft:'0px', backgroundColor:'', marginBottom:''} 


                        :!matchesM ? {transform:'scale(0.90)', marginBottom:'-20px', backgroundColor:''} 


                        : {}}

                    >

                    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>

                        <AssetCard 
                            asset_id = {listing_item['asset_id']}
                            asset_info = {listing_item['asset_info']}
                            sellAsset = {sellAsset}
                            setSellAsset = {setSellAsset}
                            assetStatus = {listing_item['assetStatus']}
                            tx_hash = {listing_item['tx_hash']}
                        />

                    </div>

                    </Grid>

                    )

                   : 


                   (

                     <Grid item xs={12} sm={isSideBarOpen ? 10 : 6} md={6} lg={4} xl={3} key={listing_item['asset_id'] + listing_item['tx_hash']}
                    ref={lastCardElementRef}

                     style={!matchesS ? {transform:'scale(1)', marginLeft:'0px', backgroundColor:'', marginBottom:''} 


                        :!matchesM ? {transform:'scale(0.90)', marginBottom:'-20px', backgroundColor:''} 

                           
                        : {}}
                    >


                     <div style={{width:'100%', display:'flex', justifyContent:'center'}}>

                        <AssetCard 
                            asset_id = {listing_item['asset_id']}
                            asset_info = {listing_item['asset_info']}
                            sellAsset = {sellAsset}
                            setSellAsset = {setSellAsset}
                            assetStatus = {listing_item['assetStatus']}
                            tx_hash = {listing_item['tx_hash']}
                        />

                    </div>

                    </Grid>

                   )
                )
            }

            </Grid>

        </Container>



        {listings && listings.length == 0 && !isLoading ? 
        <div className="blured-background" style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', 
                    height:'450px', width:'100%', top:'0px', backdropFilter: 'blur(10px)', zIndex:'0', backgroundColor:''}}>

         
            {!yourAssetsFilters.yourAssets ?

             <div style={{display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', 
                        borderRadius: '18px',width:'50vw', minWidth:'350px', backdropFilter: 'blur(20px)',height:'100%', 
                        zIndex:'0', marginBottom:'100px', marginTop:'50px', flexWrap:'wrap', maxWidth:'500px'}}>

                <div style={{fontSize: '28px', letterSpacing: '0.1rem', textShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', 
                        color: 'rgba(255, 228, 196, 0.832)', position:'absolute', top:'20px'}}>
                Nothing Found...
            </div>
            
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-60px'}}>
               <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay_Searching_small.gif" style={{maxHeight:'500px'}}/>
            </div>

            </div>

                : 


             <div style={{display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', 
                        borderRadius: '18px',width:'50vw', minWidth:'350px', backdropFilter: 'blur(20px)',height:'100%', 
                        zIndex:'0', marginBottom:'100px', marginTop:'50px', flexWrap:'wrap', maxWidth:'500px'}}>

                <div style={{fontSize: '28px', letterSpacing: '0.1rem', textShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', 
                        color: 'rgba(255, 228, 196, 0.832)', position:'absolute', top:'20px'}}>
                Looking empty...
            </div>
            
            
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-60px'}}>
               <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay_Shrugging_small.gif" style={{maxHeight:'500px'}}/>
            </div>

            </div>




            }
    
            

          </div>
      

            :  

            null
        }


        {isLoading ?
            <div style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'center',
            height:'50px', width:'100%',zIndex:'250', marginBottom:'20px', marginTop:'20px'}}> 
                <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} />
             </div>
            :null
        }

     </div>
  
  );
}

export default MarketListings;